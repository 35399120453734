import React, { Component } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { FaPen, FaTrash } from 'react-icons/fa';

class AmazonIdentifiers extends Component {
  constructor(props) {
    super(props);
    const { information = {}, organisationId } = props;
    const { amazon_identifiers = [] } = information;

    this.state = {
      identifiers: amazon_identifiers,
      showModal: false,
      currentIdentifier: { seller_id: '', vendor_id: '', approved_seller_name: '' },
      isEditing: false,
      editIndex: null,
      organisationId: organisationId,
    };
  }

  handleEdit = (index) => {
    const identifier = this.state.identifiers[index];
    this.setState({
      currentIdentifier: { ...identifier },
      showModal: true,
      isEditing: true,
      editIndex: index
    });
  }

  handleDelete = (index) => {
    const { identifiers } = this.state;
    const updatedIdentifiers = identifiers.filter((_, i) => i !== index);
    this.saveIdentifiers(updatedIdentifiers);
  }

  handleAdd = () => {
    this.setState({
      currentIdentifier: { seller_id: '', vendor_id: '', approved_seller_name: '' },
      showModal: true,
      isEditing: false,
      editIndex: null
    });
  }

  handleSave = () => {
    const { identifiers, currentIdentifier, isEditing, editIndex } = this.state;
    let updatedIdentifiers;
    if (isEditing) {
      updatedIdentifiers = identifiers.map((identifier, index) =>
          index === editIndex ? currentIdentifier : identifier
      );
    } else {
      updatedIdentifiers = [...identifiers, currentIdentifier];
    }
    this.saveIdentifiers(updatedIdentifiers);
  }

  saveIdentifiers = (updatedIdentifiers) => {
    const { organisationId } = this.state;

    this.setState({
      identifiers: updatedIdentifiers,
      showModal: false,
      currentIdentifier: { seller_id: '', vendor_id: '', approved_seller_name: '' },
      isEditing: false,
      editIndex: null
    });

    this.props.handleOrgInfoUpdate({ information: { amazon_identifiers: updatedIdentifiers } });
    setTimeout(() => {
      this.props.handleOrgInfoSubmit();
    }, 333);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      currentIdentifier: {
        ...prevState.currentIdentifier,
        [name]: value
      }
    }));
  }

  render() {
    const { identifiers, showModal, currentIdentifier } = this.state;

    return (
        <div>
          <h1>Amazon Identifiers</h1>
          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Seller ID</th>
              <th>Vendor ID</th>
              <th>Approved Seller Name</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {identifiers.map((identifier, index) => (
                <tr key={index}>
                  <td>{identifier.seller_id}</td>
                  <td>{identifier.vendor_id}</td>
                  <td>{identifier.approved_seller_name}</td>
                  <td>
                    <Button variant="link" onClick={() => this.handleEdit(index)}>
                      <FaPen />
                    </Button>
                    <Button variant="link" onClick={() => this.handleDelete(index)}>
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
            ))}
            </tbody>
          </Table>
          <Button onClick={this.handleAdd}>+ Add Identifier</Button>

          <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isEditing ? 'Edit Identifier' : 'Add Identifier'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formSellerId">
                  <Form.Label>Seller ID</Form.Label>
                  <Form.Control
                      type="text"
                      name="seller_id"
                      value={currentIdentifier.seller_id}
                      onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formVendorId">
                  <Form.Label>Vendor ID</Form.Label>
                  <Form.Control
                      type="text"
                      name="vendor_id"
                      value={currentIdentifier.vendor_id}
                      onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formApprovedSellerName">
                  <Form.Label>Approved Seller Name</Form.Label>
                  <Form.Control
                      type="text"
                      name="approved_seller_name"
                      value={currentIdentifier.approved_seller_name}
                      onChange={this.handleChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    );
  }
}

export default AmazonIdentifiers;
