import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import OrgAdminNavItem from './OrgAdminNavItem';
import OrgAdminNavHeader from './OrgAdminNavHeader';
import './OrgAdminNav.css';

class OrgAdminNav extends Component {
    static defaultProps = {
        items: []
    }

    render() {
        const {items, onNavSelect, selectedNavKey, selectedOrg = {}} = this.props;
        const filteredNavItems = items.filter((item => {
            return item && item.props && !!item.props.showFor ?
                (item.props.showFor||[]).includes(item.props.selectedOrg.type) : true;
        }))

        const navItems = filteredNavItems.map(({key, props: {label, errors} = {}}) => (
            <OrgAdminNavItem
                errors={!!errors.length}
                key={key}
                label={label}
                id={key}
                selectedNavKey={selectedNavKey}
                onNavSelect={onNavSelect}
            />
        ));
        return (
            <div className="data-capture-page-nav-container" ref={this.navContainer}>
                <nav className="data-capture-page-nav" ref={this.nav}>
                    <OrgAdminNavHeader selectedOrg={selectedOrg}></OrgAdminNavHeader>
                    <div className="data-capture-page-nav-content">
                        <div className="data-capture-page-nav-list">
                            {navItems}
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withRouter(OrgAdminNav);
