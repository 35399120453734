import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Table} from 'react-bootstrap';
import moment from 'moment';
import memoize from 'memoize-one';
import Pagination from 'components/lists/Pagination';
import { getPageItems } from "utils/pagination";

import { getLogins } from 'actions/api/users/users';


function globalStateMapper(globalState) {
    return {};
}

class LastLogins extends Component {
    constructor(props){
        super(props);
        this.state = {
            lastLogins: [],
            currentPage: 1,
            itemsPerPage: 10
        }
    }

    componentDidMount() {
        this.getLastLogins();
    }

    getLastLogins = () => {
        let { selectedUser } = this.props;

        getLogins(selectedUser.user_id).then((response) => {
            this.setState({
                lastLogins: response.data && response.data.logins && response.data.logins.data.loginAttempts
            })
        }, () => {})
    };

    onPageChanged = (currentPage) => {
        this.setState({
            currentPage
        });
    };

    paginateList = memoize(getPageItems);

    render() {
        const { lastLogins, itemsPerPage, currentPage } = this.state;
        const totalItems = Math.ceil(lastLogins.length / itemsPerPage);
        const paginatedList = this.paginateList(lastLogins, currentPage, itemsPerPage);
        
        return (
            <div className="last-logins data-capture-section">
                <Container fluid className="pt-2">
                    <h2 className="mb-3">Last Logins</h2>


                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Success</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                paginatedList.map(item => {
                                    return (
                                        <tr>
                                            <td>
                                                {moment(item.timestamp).format("YYYY-MM-DD hh:mm:ss")}
                                            </td>
                                            <td className={`status ${item.success ? 'completed' : 'failed'}`}>
                                                {item.success ? 'True' : 'False'}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            { !lastLogins.length && (
                                <tr>
                                    <td className="no-logins" colSpan="2">
                                        <span>No login attempts found.</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Pagination
                        current={currentPage}
                        total={totalItems}
                        onPageChanged={this.onPageChanged}
                    />
                </Container>
            </div>
        )
    }
}

export default connect(
    globalStateMapper
)(LastLogins);
