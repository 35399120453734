import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, FormControl, Row, Button } from 'react-bootstrap';
import listEmployees from 'actions/api/employees/list';
import updatePermissions from 'actions/api/employees/updatePermissions';
import AddUserModal from 'components/AddUserModal';
import { clone, isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import includes from "lodash/includes";

function globalStateMapper(globalState) {
    const { users } = globalState;
    return {
        users: users.list,
    };
}

class ManageUsersPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            filteredEmployees: [],
            selectedUser: null,
            showAddUserPopup: false,
            userCanView: ['view'],
            userCanInvite: ['invite', 'view', 'edit', 'delete'],
            filterValue: ''
        };
        this.filterInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchEmployees();
    }

    toggleAddUserPopup = () => {
        const { showAddUserPopup } = this.state;
        this.setState({ showAddUserPopup: !showAddUserPopup });
    }

    handleUserSelect = (selectedUser) => {
        this.setState({ selectedUser });
    }

    fetchEmployees = () => {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};
        listEmployees(organisation_id)
            .then((data) => {
                this.setState({ employees: data, filteredEmployees: data })
            })
            .catch(err => alert(err.message));
    }

    handleDeleteEmployee = (employee) => {
        // Implement the delete functionality here
    }

    handleSwitch = (e, user) => {
        let { selectedOrg } = this.props;
        let { organisation_id } = selectedOrg || {};
        let employeesClone = clone(this.state.employees);
        employeesClone.map(userItem => {
            if (user.employee_id === userItem.employee_id) {
                userItem.permissions.system.users.can = e.currentTarget.checked ? this.state.userCanInvite : this.state.userCanView;
            }
            return userItem;
        });

        updatePermissions(organisation_id, user.permissions, user.employee_id)
            .then((data) => {
                this.fetchEmployees();
            })
            .catch(err => alert(err.message));
    }

    filterEmployees = (value) => {
        const { employees } = this.state;
        this.setState({ filterValue: value });
        if (!value) {
            this.setState({ filteredEmployees: employees });
            return;
        }
        const filteredEmployees = filter(employees, item => {
            return includes(item.email.toLowerCase(), value.toLowerCase());
        });
        this.setState({ filteredEmployees });
    }

    clearFilter = () => {
        this.filterInputRef.current.value = '';
        this.filterEmployees('');
    }

    checkIfCanInvite = (user) => {
        if (user.permissions && user.permissions) {
            return isEqual(user.permissions.system.users.can.sort(), ['invite', 'view', 'edit', 'delete'].sort());
        }
    }

    render() {
        const { selectedOrg, users = [] } = this.props;
        const { organisation_id = null } = selectedOrg;
        const { filteredEmployees, showAddUserPopup, filterValue } = this.state;

        return (
            <div className="Employees data-capture-section p-0 mt-5">
                <Container fluid className="pt-2">
                    <h2>Manage Permissions </h2>
                    <Row>
                        <div className={'user-permissions-list-wrapper'}>
                            <table className="table table-lg table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        <div className='email-wrapper'>
                                            <span>Email</span>

                                            <div className={`filter-input-box`}>
                                            <FormControl
                                                type="text"
                                                autoFocus={true}
                                                placeholder="Filter"
                                                className="x-filter-bar-search-box email-filter-box"
                                                onChange={(event) => this.filterEmployees(event.target.value || '')}
                                                ref={this.filterInputRef}
                                            />
                                            <span
                                                className={`filter-cleaner`}
                                                onClick={() => this.clearFilter()}>✕</span>
                                        </div>
                                        </div>
                                    </th>
                                    <th>Manage Users</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredEmployees.map(user => {
                                    return (
                                        <tr key={user.user_id} className={''}>
                                            <td>
                                                {user.email}
                                            </td>
                                            <td className="fake-checkbox-switcher">
                                                <label>
                                                    <input defaultChecked={this.checkIfCanInvite(user)}
                                                           onChange={e => {
                                                               this.handleSwitch(e, user);
                                                           }}
                                                           type="checkbox" />
                                                    <span className="fake-switcher"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {
                                    filteredEmployees.length === 0 && (
                                        <tr>
                                            <td colSpan={2} style={{ textAlign: 'center' }}>
                                                No data available {filterValue && `for filter value "${filterValue}"`}
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </Row>
                </Container>
                <AddUserModal addUserCallback={this.fetchEmployees} show={showAddUserPopup}
                              togglePopUp={this.toggleAddUserPopup} organisationId={organisation_id}></AddUserModal>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(ManageUsersPermissions);
