import axios from 'axios';
import config from 'config';

export function getLogins(userId) {
    try {
        return axios ({
            method: 'get',
            url: `${config.get('ADMIN_URL')}users/${userId}/logins`
        }).then((response) => {
            return response && response.data
        });
    } catch (error) {
        return error;
    }
}