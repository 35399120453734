import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';
import './Modules.css';
import updateModuleOrgList from '../../../actions/api/modules/create';
import orgModuleList from '../../../actions/api/modules/org-list';
import listModules from '../../../actions/api/modules/list';
import ErrorAlert from 'components/ErrorAlert';

function globalStateMapper(globalState) {
    return {};
}

class Modules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allModules: [],
            activeModules: [],
        }
    };

    onSelectLinkOrg(e) {
        let selectedLinkOrg = JSON.parse(e.target.value);
        this.setState({selectedLinkOrg});
    }

    fetchModules() {
        let {selectedOrg} = this.props;
        let {organisation_id} = selectedOrg || {};
        this.organisation_id = organisation_id;
        listModules()
            .then((data) => {
                this.setState({allModules: data})
            })
            .catch(err => alert(err.message));
        orgModuleList(organisation_id)
            .then((data) => {
                this.setState({activeModules: data})
            })
            .catch(err => alert(err.message));
    }

    componentDidMount() {
        this.fetchModules();
    }

    handleModuleCheckboxChange(e, moduleItem, modules) {
        clearTimeout(this.checkboxTimer);
        moduleItem.enabled = e.target.checked;
        const updatedList = modules.filter(item => !!item.enabled).map(item => item.short_code);
        this.checkboxTimer = setTimeout(() => {
            updateModuleOrgList(this.organisation_id ,updatedList).then(resp => {
                this.fetchModules();
            })
        }, 800);
    }


    render() {
        const {allModules, activeModules} = this.state;
        const {errors, revalidate} = this.props;
        const modules = allModules.map(module => {
            module.enabled = activeModules.includes(module.short_code);
            return module;
        })

        return (
            <div className="Modules data-capture-section">
                <ErrorAlert errors={errors}  revalidate={revalidate}></ErrorAlert>

                <Container fluid className="">
                    <h2>Available Modules</h2>
                    <div className="module-list">
                        {
                            modules.map((moduleItem) => {
                                return <label className="module-list-item">

                                    <div className="fake-checkbox-switcher">
                                        <label>
                                            <input defaultChecked={moduleItem.enabled}
                                                   value={moduleItem.enabled}
                                                   onChange={e => {
                                                       this.handleModuleCheckboxChange(e, moduleItem, modules);
                                                   }}
                                                   type="checkbox"/>
                                            <span className="fake-switcher"></span>
                                        </label>
                                    </div>
                                    <div>
                                        {moduleItem.name}
                                    </div>
                                </label>
                            })
                        }
                    </div>
                </Container>
            </div>
        );
    }
}

export default connect(
    globalStateMapper,
    null
)(Modules);
